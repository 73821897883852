import { Grid } from "@mui/material";
import React, {useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom'; // useNavigate hook for navigation


import TopImage from "../images/new/about/section1.jpg"
import MidImage from "../images/new/about/section2.jpg"
import BottomImage from "../images/new/about/section3.png"

import Values from "../images/new/about/values.svg"

import Robots101 from "../images/new/about/Robots101.png"
import ROAR from "../images/new/about/roar.png"
import DRC from "../images/new/about/drc.png"

import Boeing from "../images/new/about/boeing.svg"
import BPEQ from "../images/new/about/BPEQ.png"
import EER from "../images/new/about/EER.png"
import MakerHero from "../images/new/about/makerhero.svg"
import QCR from "../images/new/about/QCR.svg"


import "../css/about.css"

const Paragraph1 = "The QUT Robotics Club is a student-led organization dedicated to fostering robotics education. Open to all skill levels, it provides a supportive space for hands-on learning, encouraging collaboration and innovation. Members engage in creative engineering projects, forming lasting connections within a strong robotics community."
const Paragraph2 = "The club helps members develop skills in design, programming, and engineering through workshops, projects, and competitions. It also hosts industry talks and networking events, connecting students with professionals and career opportunities. These experiences equip members for future success while building lasting friendships and support networks."

const Paragraph3 = "The QUT Robotics Club offers exciting projects for all skill levels. Robots101 provides workshops for beginners to learn robotic design and coding. For a greater challenge, the club competes in the Australian Rover Challenge (ARCh), where teams build a Mars-style rover to complete complex tasks in a simulated environment."
const Paragraph4 = "The club also hosts the Droid Racing Competition (DRC), where members design autonomous vehicles to navigate an obstacle-filled track. This helps develop skills in sensor integration, real-time control, and machine learning. These projects offer hands-on experience, teamwork, and problem-solving opportunities for future careers."

const Paragraph5 = "Networking and industry connections are vital for success in robotics and engineering, and the QUT Robotics Club helps foster these relationships. Through industry talks, networking events, and mentorship, members gain insights from professionals, opening doors to internships and careers. These connections keep students informed on emerging technologies and real-world applications, bridging academic learning with industry practice. We extend a big thank you to our sponsors for making these valuable opportunities possible and supporting innovation."

const About = () => {

    useEffect(() => {
            const handleScroll = () => {
            const elements = document.querySelectorAll(".animate-on-scroll");
            elements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < window.innerHeight - 200) {
                element.classList.add("visible");
                }
            });
            };
        
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, []);

    const navigate = useNavigate(); // Initialize the navigate hook
    
    
    const handleClick = () => {
        navigate('/sponsors'); // Navigate to the /about page
        };

    return(
        <div>
            {/*Top Section - Text Body 1 */}
            <div className="about-text-Section">
                <Grid container spacing={2} className="section-container">
                    <Grid item xs={12} md={5} className="image-container">
                        <img src={TopImage} alt="Top" />
                    </Grid>
                    <Grid item xs={12} md={7} className="text-container">
                        <div>
                            <h2><i>For Students, By Students</i></h2>
                            <p>{Paragraph1}</p>
                            <p>{Paragraph2}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/*Values Section - Values Image */}
                <div className="about-values">
                    <h2>Our Values</h2>
                    <img className="animate-on-scroll" src={Values} alt="Error Loading"/>
                </div>

            
            {/*Project Section - Text Body 2 */}
            <div className="about-text-Section">

                <Grid container spacing={2} className="section-container">
                    <Grid item xs={12} md={7} className="text-container">
                        <div className="animate-on-scroll">
                            <h2><i>From Begineer to Advanced</i></h2>
                            <p>{Paragraph3}</p>
                            <p>{Paragraph4}</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={5} className="image-container">
                        <img src={MidImage} alt="Error Loading" />
                    </Grid>
                </Grid>
            </div>


            {/*Project Links - Links to projects */}

            <div className="about-projects">
                <h2>Our Projects</h2>
                <Grid container spacing={3} className="about-projects-container">
                    <Grid item xs={12} md={4} className="about-project-link">
                        <Link to="/robots101" className="project-button animate-on-scroll">
                            <img src={Robots101} alt="Error Loading" />
                            <h3>Robotics 101</h3>
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={4} className="about-project-link">
                        <Link to="/drc" className="project-button animate-on-scroll">
                            <img src={DRC} alt="Error Loading" />
                            <h3>Droid Racing Competition</h3>
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={4} className="about-project-link">
                        <Link to="/arc" className="project-button animate-on-scroll">
                            <img src={ROAR} alt="Error Loading" />
                            <h3>Remote Offworld Autonomous Rover</h3>
                        </Link>
                    </Grid>
                </Grid>
            </div>


            {/*Sponsors section - Text Body 3 */}

            <div className="about-text-Section">
                <Grid container spacing={2} className="section-container">
                    <Grid item xs={12} md={5} className="image-container">
                        <img src={BottomImage} alt="Error Loading" />
                    </Grid>
                    <Grid item xs={12} md={7} className="text-container">
                        <div className="animate-on-scroll">
                            <h2><i>Industry, Networking and Community</i></h2>
                            <p>{Paragraph5}</p>
                            <div className="about-sponsor-button-container">
                                <button  onClick={handleClick} className="about-sponsor-button">Sponsor Us</button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/*Sponsors Links -  */}

            <div className="about-sponsors">
                <h2>Our Sponsors</h2>
                <Grid container spacing={3} className="about-sponsor-container animate-on-scroll">
                    <Grid item xs={12} md={4} className="about-sponsor">
                        <img src={Boeing} alt="Error Loading"></img>
                    </Grid>
                    <Grid item xs={12} md={4} className="about-sponsor">
                        <img src={BPEQ} alt="Error Loading"></img>
                    </Grid>
                    <Grid item xs={12} md={4} className="about-sponsor">
                        <img src={MakerHero} alt="Error Loading"></img>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="about-sponsor-container animate-on-scroll">
                    <Grid item xs={12} md={4} className="about-sponsor">
                        <img src={EER} alt="Error Loading"></img>
                    </Grid>
                    <Grid item xs={12} md={4} className="about-sponsor">
                        <img src={QCR} alt="Error Loading"></img>
                    </Grid>
                </Grid>
            </div>

            {/*ABN and Location*/}
            <div className="about-footer">
                <p className="about-footer-abn">ABN: 34 411 341 964</p>
                <p className="about-footer-text">
                    Reach out to us via our <a href="/contact" className="about-footer-link">contact form</a> or through social media.
                </p>
                <p className="about-footer-address">
                    Address: 920 S Block, QUT Gardens Point Campus, 2 George St, Brisbane City QLD 4000
                </p>
            </div>


        </div>
    );
}
export default About;