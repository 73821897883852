import React from "react";
import Headshot from "../components/headshot.js";
import {useState, useEffect} from "react";
import {getExecs} from "../db/data.js";

import Backdrop from "../images/new/team/QUTRC_Team.jpg";
import Bonus from "../images/new/team/QUTRC_Team_Bonus.jpg";

import "../css/team.css"

const Team = () => {
    const [execs, setExecs] = useState([]);
    const [image, setImage] = useState(Backdrop);

    useEffect(() => {
        getExecs()
            .then(execs => {
                setExecs(execs);
            });
    }, []);

    const handleChangeImage = () => {
        setImage(image === Backdrop ? Bonus : Backdrop);
    };

    useEffect(() => {
            const handleScroll = () => {
            const elements = document.querySelectorAll(".animate-on-scroll");
            elements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < window.innerHeight - 200) {
                element.classList.add("visible");
                }
            });
            };
        
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, []);

  return (
    <div>
    <div className="team-container">
        <img src={image} alt="Robotics" height="100%" width="100%" />
        <div className="team-overlay"></div>
        <div className="team-text">
          <h1>Our 2025 Executive Team</h1>
        </div>
    </div>
    <button onClick={handleChangeImage} className="invisible-button">Change Image</button>
    <div className="team-page">
      <h1>Meet the Team!</h1>
      <div className="headshot-container">
        {execs.map((exec) => (
            <Headshot key={exec.id} exec={exec} />
        ))}
      </div>
    </div>
    </div>
  );
};

export default Team;
