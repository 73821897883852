import React, { useState } from "react";
import roar1 from '../images/roar_gallery/roar1.JPG';
import roar2 from '../images/roar_gallery/roar2.JPG';
import roar3 from '../images/roar_gallery/roar3.JPG';
import roar4 from '../images/roar_gallery/roar4.JPG';
import roar5 from '../images/roar_gallery/roar5.JPG';
import roar6 from '../images/roar_gallery/roar6.JPG';
import roar7 from '../images/roar_gallery/roar7.JPG';
import roar8 from '../images/roar_gallery/roar8.JPG';
import roar9 from '../images/roar_gallery/roar9.JPG';
import roar10 from '../images/roar_gallery/roar10.JPG';

const images = [roar1, roar2, roar3, roar4, roar5, roar6, roar7, roar8, roar9, roar10];

const Gallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div>
            <style>
                {`
                    .gallery-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        padding: 10px;
                        border-radius: 10px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        width: 90%;
                        height: 90%;
                        margin: auto;
                    }
                    .gallery-image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                    .gallery-button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: navy;
                        color: white;
                        border: none;
                        padding: 10px;
                        border-radius: 50%;
                        cursor: pointer;
                        font-size: 20px;
                        line-height: 20px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .gallery-button:hover {
                        background-color: darkblue;
                    }
                    .prev-button {
                        left: 10px;
                    }
                    .next-button {
                        right: 10px;
                    }
                `}
            </style>
            <div className="gallery-container animate-on-scroll">
                <button onClick={prevImage} className="gallery-button prev-button">&#9664;</button>
                <img src={images[currentIndex]} alt={`Gallery ${currentIndex + 1}`} className="gallery-image" />
                <button onClick={nextImage} className="gallery-button next-button">&#9654;</button>
            </div>
        </div>
    );
}

export default Gallery;