import { useNavigate } from 'react-router-dom'; // useNavigate hook for navigation
import React from 'react';

import Backdrop from "../images/new/home/ARC-HP.jpg";
import "../css/home.css";


// Main Home Component
const Home = () => {
  const navigate = useNavigate(); // Initialize the navigate hook

  const handleClick = () => {
    navigate('/about'); // Navigate to the /about page
  };


  return (
    <div className="home-container">
      <div className="home-text-section">
        <h1>QUT Robotics Club</h1>
        <h3>Supporting Emerging Engineers</h3>
        <button  onClick={handleClick} >About Us</button>
      </div>
      <div className="home-image-section">
        <img src={Backdrop} alt="Example" />
      </div>
    </div>
  );
};

export default Home;
