import React from "react";


import "../css/events.css"


  


const Events = () => {

    return (

        <div className="events-container">



                    <h1>Our Latest News</h1>
                <div className="news-instragram-container">
                    <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
                    <iframe
                        src="//lightwidget.com/widgets/712a7da1f3ab581e8b7e75ccd744c9bd.html" 
                        scrolling="yes" 
                        allowTransparency="true" 
                        className="lightwidget-widget"
                        title="Instragram Widget"
                    >
                    </iframe>
                </div>
        </div>



    )
}

export default Events;