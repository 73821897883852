import {Route, Routes} from "react-router-dom";

import React from 'react';


// main pages
import Home from "./pages/home.js";
import About from "./pages/about.js"


import Team from "./pages/team.js";

//Quarantine pages

import Robots101 from "./pages/robots101.js"
import ARC from "./pages/arc.js"

import Events from "./pages/events_page.js";
import DRC from "./pages/drc.js";
import SponsorsPage from "./pages/sponsors_page.js";
import ContactForm from "./pages/contact.js";

// developer page
import Developers from "./pages/developers.js";


// error pages
import NotFound from "./pages/404.js";




// components
import Header from "./components/navbar.js";
import Footer from "./components/footer.js";
import ScrollToTop from "./components/scroll_to_top.js";

// data


function App() {
  // Initialize Google Analytics with your tracking ID

  return (
    <div className="App">
        <style>{"body { background-color: #fff; }"}</style>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route index element={<Home />} />

          {/*Quarantine page*/}
          <Route path="/about" element={<About />} />

          <Route path="/team" element={<Team />} />
          <Route path="/robots101" element={<Robots101 />} />
          <Route path="/arc" element={<ARC />} />

          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/sponsors" element={<SponsorsPage />} />
          <Route path="/developers" element={<Developers />} />


          {/* DRC pages */}
          <Route path="drc">
            <Route index element={<DRC />} />
          </Route>


          {/*Error routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>

        <div id="footer-div" />
        <Footer />
    </div>
  );
}

export default App;
