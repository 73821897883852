import React, { useState } from "react";
import { Grid } from "@mui/material";

import {useEffect} from "react";


import "../css/drc.css";

import drc_image_1 from "../images/new/drc/drc-1.jpg"
import drc_image_2 from "../images/new/drc/drc-2.jpg"
import drc_image_3 from "../images/new/drc/drc-3.jpg"
import drc_image_4 from "../images/new/drc/drc-4.jpg"
import drc_image_5 from "../images/new/drc/drc-5.jpg"
import drc_image_6 from "../images/new/drc/drc-6.jpg"
import drc_image_7 from "../images/new/drc/drc-7.jpg"
import drc_image_8 from "../images/new/drc/drc-8.jpg"

import results_2024 from "../images/new/drc/DRC_2024_Results.png"
import results_2023 from "../images/new/drc/DRC_2023_Results.png"
import results_2022 from "../images/new/drc/DRC_2022_Results.png"



const para1 = "The Droid Racing Challenge (DRC) is a student robotics challenge focusing on the development of ground-based autonomous robotic racing platforms. The “droids” that take part in this competition will feature autonomous operation, robotic vision, navigation and obstacle avoidance, and mechanical design optimised for speed. The Challenge has been set so that a small team of undergraduate students should be able to complete a capable robot in one semester."

const para2 = "Design and build a completely autonomous system capable of racing around a track, denoted by two coloured lines; all while avoiding collisions with other vehicles and obstacles. This is done through the use of computer vision. By leaving the rest of the robot design open ended it is hoped that teams will find creative and innovative solutions to the problem."


const DRC = () => {

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  const faqs = [
    {
      question: "Is the team size limited?",
      answer:
        "There is a limit of 6 official members per team. We recommend having at least 4 per team: 2 software programmers; 1 in charge of electrical and 1 for mechanical requirements for each team. There may be no more than three teams representing a participating institution.",
    },
    {
      question: "Is the budget limited?",
      answer:
        "The total budget of the final product must be no more than $1500. As all components will be at the expense of the teams, a budget limit is imposed to ensure a level playing field.",
    },
    {
      question:
        "Is it essential to have an official team supervisor from our university/high-school staff?",
      answer:
        "Yes, this is a requirement of the competition. Each university/high-school will be required to have at least one staff supervisor contact us by June 3rd for a progress update to ensure that the team(s) will meet the requirements and rules of the challenge and be ready by the final.",
    },
    {
      question:
        "What components can be used? Can a robot kit/commercial robot chassis or similar be used?",
      answer:
        "Any components can be used, as long as they meet the rules and stay within the budget.",
    },
    {
      question: "Are there software or processing restrictions?",
      answer:
        "None. Any programming language, software or processing regime may be used, including off board. However, any off board links must be wireless and present at the event, cloud based solutions are on a case by case basis.",
    },
    {
      question: "Are postgraduates allowed to participate?",
      answer:
        "This competition is designed for undergraduate students, therefore a maximum of 1 postgraduate student is allowed per team.",
    },
    {
      question: "Can we use any sensors/actuators we wish?",
      answer:
        "Vision should be the main navigation sensing method, but it may be supplemented with other sensing methods. LiDARS, GPS and over head camera systems are not allowed. Any motors etc. may be used. Basically if it can fit on the robot with no external wires and vision is the primary navigation system, you can use it.",
    },
    {
      question: "Is the source of energy limited i.e. batteries only?",
      answer:
        "The source of energy is not limited to batteries. Try to avoid things like huge capacitors due to the risk of them blowing up. Please be aware of the restrictions/requirements for shipping for airlines regarding batteries as you will need to adhere to these requirements when shipping/accompanying your robot to the challenge. Do not rely on solar power as the competition is indoors.",
    },
    {
      question: "What is the race track surface like?",
      answer:
        "The track is on your common hard rubber lab flooring. It will be held in O134, this is indoors and is almost completely flat with minor imperfections. We would recommend soft, wide rubber tyres at least 5cm in diameter like those found on remote controlled toy cars etc. The surface can make RC Cars drift really well.",
    },
    {
      question: "What can I do if I want to start preparing now?",
      answer:
        "This website and the droid racing pages will be regularly updated with more information. You can also explore OpenCV, a well known computer vision library available for a number of platforms. You can investigate the type of sensors you might like to use for obstacle avoidance. You can think about what kind of mechanical design will be fastest. There are also previous years’ photos and videos on Facebook and YouTube to explore. And you can contact us for more information.",
    },
  ];
  
  useEffect(() => {
          const handleScroll = () => {
          const elements = document.querySelectorAll(".animate-on-scroll");
          elements.forEach((element) => {
              const elementTop = element.getBoundingClientRect().top;
              if (elementTop < window.innerHeight - 200) {
              element.classList.add("visible");
              }
          });
          };
      
          window.addEventListener("scroll", handleScroll);
          return () => window.removeEventListener("scroll", handleScroll);
      }, []);
  

  return (
    <div className="drc-page">
      {/* Title Banner */}
      <div className="drc-banner">
        <h1 className="title">Droid Racing Competition</h1>
        <div className="title-underline"></div>
      </div>

      {/*Starter Text */}
      <div className="drc-upper-text">
        <h3>Gear up for an exciting challenge where innovation meets speed! Design, program, and race your autonomous droid to victory in the ultimate robotics showdown</h3>
      </div>

      <div className="about-text-Section">
        <Grid container spacing={2} className="section-container">
            <Grid item xs={12} md={5} className="image-container">
                <img src={drc_image_5} alt="Top" />
            </Grid>
            <Grid item xs={12} md={7} className="drc-text-container">
                <div>
                    <h2><i>Droid Racing - Where Software meets Hardware</i></h2>
                    <p>{para1}</p>
                </div>
            </Grid>
        </Grid>
      </div>

      <div className="drc-results-wrapper">
        <h1>Our Winners</h1>
        <Grid container={3}>
          <Grid item xs={12} md={4} className="drc-results-container animate-on-scroll">
              <img src={results_2023} alt="Top" />
          </Grid>
          <Grid item xs={12} md={4} className="drc-results-container animate-on-scroll">
              <img src={results_2024} alt="Top" />
          </Grid>
          <Grid item xs={12} md={4} className="drc-results-container animate-on-scroll">
              <img src={results_2022} alt="Top" />
          </Grid>
        </Grid>
      </div>


      <div className="about-text-Section drc-results-wrapper">

          <Grid container spacing={2} className="section-container">
              <Grid item xs={12} md={7} className="drc-text-container">
                  <div>
                      <h2><i>The Challenge:</i></h2>
                      <p>{para2}</p>
                  </div>
              </Grid>

              <Grid item xs={12} md={5} className="image-container">
                  <img src={drc_image_1} alt="Top" />
              </Grid>
          </Grid>
      </div>

      <div className="drc-EOI-wrapper">
        <div className="roar-contact-container animate-on-scroll">
          <h1>Do you want to compete in our 2025 Competition?!?</h1>
          <h3>
            Whether it’s your first robotics competition or another exciting challenge, QUT’s Droid Racing Challenge (DRC) is a great way to test your skills, collaborate with peers, and tackle real-world engineering problems. With a focus on vision-based navigation, DRC helps you build technical expertise, gain hands-on experience, and connect with the robotics community—all while having fun!        
          </h3>
          <h3>
          Use the Expression of Interest link below to sign up and be the first to receive news and updates!
          </h3>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdHztGUmrRdUaZdHoFacz5WuI1-rRxoRKrvTfLqCNtMvxWeMg/viewform" className="drc-link" target="_blank" rel="noopener noreferrer">
            Sign Up Here!
            </a>
          </div>
      </div>



      <h2 className="DRC-faq-title">Our 2024 Gallery:</h2>

      <Grid container spacing={3} className="section-container drc-gallery-container">
          <Grid item xs={6} md={4} className="image-container">
              <img src={drc_image_3} alt="Top" />
          </Grid>
          <Grid item xs={6} md={4} className="image-container">
              <img src={drc_image_7} alt="Top" />
          </Grid>
          <Grid item xs={12} md={4} className="image-container">
              <img src={drc_image_2} alt="Top" />
          </Grid>
          <Grid item xs={6} md={4} className="image-container">
              <img src={drc_image_6} alt="Top" />
          </Grid>
          <Grid item xs={6} md={4} className="image-container">
              <img src={drc_image_8} alt="Top" />
          </Grid>
          <Grid item xs={12} md={4} className="image-container">
              <img src={drc_image_4} alt="Top" />
          </Grid>
          
      </Grid>



      <div className="DRC-faq-container">
          <h2 className="DRC-faq-title">Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`DRC-faq-item ${openIndex === index ? "open" : ""}`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="DRC-faq-question">{faq.question}</div>
              <div className="DRC-faq-answer">{faq.answer}</div>
            </div>
          ))}
        </div>


    </div>
  );
};

export default DRC;
