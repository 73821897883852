import React, { useState, useRef } from 'react'
import '../css/contact.css'
import { useNavigate } from "react-router-dom";
import * as emailjs from '@emailjs/browser';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedIn from "@mui/icons-material/LinkedIn";
import Instragram from "@mui/icons-material/Instagram";

//import DRC from "../images/gallery photos/10.jpg";
//import Hackathon from "../images/gallery photos/2.jpg";



const Contact = () => {
	// Using this external api to send the email: https://www.emailjs.com/
	// docs: https://www.emailjs.com/docs/
	const serviceID = 'default_service';
	const templateID = 'template_ptjo7zq';
	const publicKey = 'tC_TZYrIVW_yXSAes';

	let contactForm = useRef();
	const navigate = useNavigate();
	const [isLoading, setLoading] = useState(false);

	const submit = (e) => {
		e.preventDefault()
		setLoading(true);

		try {
			emailjs.sendForm(serviceID, templateID, contactForm.current, publicKey)
				.then((response) => {
					console.log('SUCCESS!', response.status, response.text);
					setLoading(false);
					navigate('/thank_you');
				})
		} catch(e) {
			console.log('FAILED...', e);
			setLoading(false);
			window.alert('Sorry, something went wrong. Please try again later.')
		}
	};
		

	return (
		<div className='contact-page'>
			
			
					<h1>Contact us</h1>
					<h2>Our Socials: </h2>
					<div className='contact-icons'>
						<a href='https://www.facebook.com/groups/qutroboticsclub/'>
							<FacebookIcon style={{ color: "#0f1627", fontSize: 100 }} />
						</a>
						<a href='https://www.linkedin.com/company/qut-robotics-club/'>
							<LinkedIn style={{ color: "#0f1627", fontSize: 100 }} />
						</a>
						<a href='https://www.instagram.com/qut.roboticsclub/'>
							<Instragram style={{ color: "#0f1627", fontSize: 100 }} />
						</a>
					</div>

					<form id='contact-form' ref={contactForm} onSubmit={submit}>
						<h3>Please use the form below to contact us for an email response. <br/> We will get back to you as soon as possible.</h3>
						
						<input id='name' name='name' type='text' placeholder='Name' required style={{ 'fontFamily': "Montserrat" }}/>
						<input id='email' name='email' type='email' placeholder='Email' required style={{ 'fontFamily': "Montserrat" }}/>
						<textarea id='message' name='message' placeholder='Message' style={{ 'resize': "vertical", 'fontFamily': "Montserrat"  }} rows='3' required />
						
						<button type='submit' className='submit-btn'>
							{isLoading ? 'Loading...' : 'Submit'}
						</button>
					</form >			
		</div>
	)
}

export default Contact