import React, {useEffect, useState} from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedIn from "@mui/icons-material/LinkedIn";
import Headshot from "../components/headshot.js";
import {getDevs} from "../db/data.js";

import '../css/developers.css'

const Developers = () => {
    const [developers, setDevelopers] = useState([])

    useEffect(() => {
        getDevs()
            .then(devs => {
                setDevelopers(devs)
            })
    }, [])

  return (
    <div className="developers-page">
      <h1>Developers</h1>
      <ul className="headshot-container-dev">

        {developers.map(developer => (
          <li key={developer.id} id={developer.firestoreId} className="developer">
            <Headshot exec={developer} />

            <div className="developer-links">
              <a href={developer.github} target="__blank">
                <GitHubIcon style={{ color: "#0f1627", fontSize: 75, marginTop: "5%" }} />
              </a>
              {developer?.linkedin && (
                <a href={developer.linkedin} target="__blank">
                  <LinkedIn style={{ color: "#0f1627", fontSize: 75 }} />
                </a>
              )}
            </div>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default Developers;
