import React, {useEffect} from 'react';
import { Grid } from "@mui/material";

import robot101_cover from "../images/new/robots101/Robots101-cover.jpg"
import robot101_1 from "../images/new/robots101/Robots101-1.jpg"
import robot101_2 from "../images/new/robots101/Robots101-2.jpg"
import robot101_3 from "../images/new/robots101/Robots101-3.jpg"
import robot101_4 from "../images/new/robots101/Robots101-4.jpg"


import qut_map from "../images/qut-map.jpg";

import '../css/Robots101.css'

const Proj_Manager = {
    imagePath: `/exec_photos/baeddan.jpg`,
    position: 'Project Manager',
    name: "Baeddan Davies"
};

const Assist_Proj_Manager = {
    imagePath: '/exec_photos/Robot.png',
    position: 'Assistant Project Manager',
    name: "Unfilled"
};




  const ExecImage = ({ exec }) => {
    return (

          <div className='robots101-project-headshot'>
            <div className="robots101-headshot-image-border">
              <img
                className="robots101-headshot-image"
                src={exec.imagePath}
                alt={exec.name}
              />
            </div>
            <h2>{exec.name}</h2>
            <h5>{exec.position}</h5>  
          </div>
    );
  }


function Robots101() {

    useEffect(() => {
        const handleScroll = () => {
          const elements = document.querySelectorAll(".animate-on-scroll");
          elements.forEach((element) => {
            const elementTop = element.getBoundingClientRect().top;
            if (elementTop < window.innerHeight - 200) {
              element.classList.add("visible");
            }
          });
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);



  return (


    <div>
        <Grid container spacing={2} >
            <Grid item xs={12}  md={6} >
                <img src={robot101_cover} alt="Robotics" height='100%' width = "100%" />
            </Grid>
            <Grid item xs={12}  md={6} className='robots101-top-section'>
              <div className='roar-contact-container'>
                <h1><i>The First Step in Robotics</i></h1>
                <h3> Robotoics 101 is our introductory program that covers all the basics of robotics and engineering. Wether you are a first year student trying too get ahead or an experienced engineer wanting to learn new skills there is somnething for everyone</h3>
              </div>
            </Grid>
        </Grid>


        <h2 className='robotics101-grid-titls'>The Details</h2>

        <div className="robotics101-grid-container">
          <div className="robotics101-grid-wrapper">
            <div>
                  {/*Row 1 - Who */}
                  <Grid container spacing={4}>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>                <div>
                    <h3>Who Teaches Robots101</h3>
                    <p>Robots 101 is taught by our Project Manager {Proj_Manager.name} and Assitant {Assist_Proj_Manager.name}. They are both skilled engineers who understnd the challenges many new engineers may face</p>
                  </div></div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-white'>

                    <ExecImage exec={Proj_Manager}/>

                  </div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>
                    <ExecImage exec={Assist_Proj_Manager}/>
                  </div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-image'><img src={robot101_1} alt="Error Loading" /></div></Grid>


                  {/*Row 2 */}

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-image'><img src={robot101_2} alt="Error Loading" /></div></Grid>


                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>                <div>
                    <h3>What is taught in Robotics 101?</h3>
                    <p>Robotics 101 takes place over two semesters, with semester 1 covering 3D printing, robot design and assembly, and semester 2 focusing on actually programming and modding the robot </p>
                  </div></div></Grid>

                  
                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-white'>
                    <div>
                      <h3>Semester 1:</h3>

                      <ul>
                        <li>3D Modelling and Printing</li>
                        <li>PCB Theory and Design using KiCAD</li>
                        <li>Robot Assembly</li>
                        <li>Introduction to Linux</li>
                      </ul>

                    </div>
                  </div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>
                  <div>
                      <h3>Semester 2:</h3>

                      <ul>
                        <li>Introduction to Programming / Python</li>
                        <li>Computer Vision</li>
                        <li>PWM / Motor Control</li>
                        <li>System Automation</li>
                      </ul>

                    </div>
                  </div></Grid>


                  {/*Row 3 */}

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>                <div>
                    <h3>Where should I go?</h3>
                    <p>Simply head to S901 at Gardens Point Campus.</p>
                  </div></div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-image'><img src={qut_map} alt="Error Loading"/></div></Grid>



                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>                <div>
                    <h3>When does it happen?</h3>
                    <p>Robotics 101 takes place every Tueday starting at 6pm going until 9pm. If you cant make it some week dont stress, all content is recorded online for your convience</p>
                  </div></div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-image'><img src={robot101_3} alt="Error Loading"/></div></Grid>




                  {/*Row 4 */}

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-image'><img src={robot101_4} alt="Error Loading" /></div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>                <div>
                    <h3>Why Should I do Robotics 101?</h3>
                    <p>Robotics 101 is open to students of all backgrounds and experience levels. Unlike university courses with strict deadlines, it offers a relaxed and fun way to learn new skills and make friends.</p>
                  </div></div></Grid>

                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-white'>                
                    <div>
                        <p><i>"Robotics 101 changed how I see engineering—I went from knowing almost nothing to building and programming my own projects in weeks."</i></p>
                        <p>- Atticus D'Mello (Student 2023)</p>
                  </div></div></Grid>
                  
                  <Grid item xs={12} sm={6} md={3} className="robotics101-grid-item animate-on-scroll"><div className='robotics101-alt'>     
                    <div className='robotics101-link'>
                      <h3>Intrested? View our course content now!</h3>
                      <button className="robots101-button" onClick={() => window.open("https://qutrc.notion.site/Robots101-Wiki-1b1459112e0a42f2ab0e6879863b7752", "_blank")}>Robotics 101</button>
                    </div>


                  </div></Grid>

                </Grid> 


            </div>
            
          </div>
        </div>




    </div>
  );
}

export default Robots101;
