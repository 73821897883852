import React from "react";
import { Grid } from "@mui/material";


import Boeing from "../images/new/about/boeing.svg"
import BPEQ from "../images/new/about/BPEQ.png"
import EER from "../images/new/about/EER.png"
import MakerHero from "../images/new/about/makerhero.svg"
import QCR from "../images/new/about/QCR.svg"
import Leap from "../images/new/sponsors/leap.png"

import top_image from "../images/new/sponsors/sponsor.jpg"

import read_more from '../db/Sponsorship_Package.pdf'

import '../css/sponsors.css'


const President = {
    imagePath: 'brianna.jpg',
    position: 'President',
    name: "Brianna Rablin",
	contact: "0431 427 654"
};

const Vice_President = {
    imagePath: 'atticus.jpg',
    position: 'Vice President',
    name: "Atticus D'Mello",
	contact: "0422 402 113"
};

const Industry_lead_1 = {
    imagePath: 'oscar.jpg',
    position: 'Industry Lead',
    name: "Oscar Jones",
	contact: "qutroboticsclub@gmail.com"
};

const Industry_lead_2 = {
    imagePath: 'matt.jpg',
    position: 'Industry Lead',
    name: "Matthew",
	contact: "qutroboticsclub@gmail.com"
};


  const ExecImage = ({ exec }) => {
    return (

  
          <div className='robots101-project-headshot'>
            <div className="robots101-headshot-image-border">
				<img
					className="robots101-headshot-image"
					src={`/exec_photos/${exec.imagePath}`}
					alt={exec.name}
              	/>
            </div>
            <h2>{exec.name}</h2>
            <h3>{exec.position}</h3>  
			<h5>{exec.contact}</h5>
          </div>
  
    );
  }


const Sponsors = () => {

	const openPdf = () => {
        // Replace with your PDF file path
        window.open(read_more, '_blank');
    };

	return (
		<div className="Sponsors">

			<div className="about-text-Section">
				<Grid container spacing={2} className="section-container">
					<Grid item xs={12} md={7} className="text-container">
						<div>
							<h2><i>Why you Should Sponsor Us</i></h2>
							<p>The QUT Robotics Club (QUTRC), established in 2011, is a student-run organization that offers students opportunities to enhance their robotics skills and build industry connections through workshops, networking, and industry presentations. Sponsors play a crucial role in supporting these activities. By sponsoring QUTRC, companies can help develop future roboticists while also gaining access to potential engineers for future collaboration.</p>
							<div className="sponsors-container">
								<button  onClick={openPdf} className="about-sponsor-button">Our Prospectus</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={5} className="image-container">
						<img src={top_image} alt="Top" />
					</Grid>
				</Grid>
			</div>

			<h1>Learn More About Us:</h1>

			<div className="video-container">
				<div className="video-wrapper">
					<iframe
					className="video-frame"
					src="https://www.youtube.com/embed/-OhEpcvHs_k"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					></iframe>
				</div>
			</div>

			<h1>Our 2025 Sponsorship Team:</h1>


			<Grid container spacing={2} className="sponsorship-team">
					<Grid item xs={12} md={3} className="robotics101-grid-item">
						<ExecImage exec={Industry_lead_1} />
					</Grid>
					<Grid item xs={12} md={3} className="robotics101-grid-item">
						<ExecImage exec={Industry_lead_2} />
					</Grid>
					<Grid item xs={12} md={3} className="robotics101-grid-item">
						<ExecImage exec={President} />
					</Grid>
					<Grid item xs={12} md={3} className="robotics101-grid-item">
						<ExecImage exec={Vice_President} />
					</Grid>
			</Grid>


			<h1>Our 2025 Sponsors:</h1>

			{/*QCR Sponsor Section */}
			<div className="sponsor-Section roar-contact-container">
				<Grid container spacing={2} className="sponsor-section-container">

					<Grid item xs={12} md={5} className="sponsor-image-container">
						<img src={QCR} alt="Queensland Centre for Robotics" />
					</Grid>

					<Grid item xs={12} md={7} className="sponsor-text-container">
						<div >
							<h2><i>Queensland Centre for Robotics</i></h2>
							<h5>The Queensland Centre for Robotics is one of the world’s leading robotic research facilities, focusing on innovation in intelligent robotics, translating research into commercial and societal outcomes, and leading the education, training, and development of talent to meet the growing demands for robotics. The QCR has provided substantial assistance and support for the Droid Racing Challenge and Artemis, our entry into the 2023 Australian Rover Challenge. They are close collaborators and a valued sponsor, as many of our students and executives have gone on to research or work there.</h5>
						</div>
					</Grid>

				</Grid>
			</div>

			{/*EER Sponsor Section */}
			<div className="sponsor-Section roar-contact-container">
				<Grid container spacing={2} className="sponsor-section-container">
					<Grid item xs={12} md={8} className="sponsor-text-container">
						<div>
							<h2><i>Electrical Engineering and Robotics</i></h2>
							<h5>The QUT Robotics Club is supported by the QUT School of Electrical Engineering and Robotics (EER). The School of EER aims to improve how we understand and take care of our world through sustainable energy solutions and intelligent technology. They focus on high-quality, cross-disciplinary teaching and research in robotic vision, machine learning, video analytics, wireless power transfer, microgrids, renewable energy integration, and superconductivity. The School of EER provides financial support for running our projects, especially our Robots101 program and our Australian Rover Challenge entries. They also provide workshop space to complete our projects.</h5>
						</div>
					</Grid>
					<Grid item xs={12} md={4} className="sponsor-image-container">
						<img src={EER} alt="Electrical Engineering and Robotics" />
					</Grid>
				</Grid>
			</div>

			{/*Makerhero Sponsor Section */}
			<div className="sponsor-Section roar-contact-container">
				<Grid container spacing={2} className="sponsor-section-container">
					<Grid item xs={12} md={5} className="sponsor-image-container">
						<img src={MakerHero} alt="Maker Hero" />
					</Grid>
					<Grid item xs={12} md={7} className="sponsor-text-container">
						<div>
							<h2><i>Maker Hero</i></h2>
							<h5>Maker Hero is an Australian startup that empowers students and creators to bring their innovative ideas to life. They provide easy access to a wide range of electronics, tools, and resources to assist in the prototyping and creation of projects, from beginner to expert level. Through their support, we have been able to enhance our technical capabilities and bring even more advanced designs to life for various club projects and challenges.</h5>
						</div>
					</Grid>
				</Grid>
			</div>

			{/*Boeing Sponsor Section */}
			<div className="sponsor-Section roar-contact-container">
				<Grid container spacing={2} className="sponsor-section-container">
					<Grid item xs={12} md={7} className="sponsor-text-container">
						<div>
							<h2><i>Boeing</i></h2>
							<h5>Boeing is a leader in aerospace innovation, with decades of experience in manufacturing and developing state-of-the-art aerospace technology. Their partnership with the QUT Robotics Club has provided invaluable mentorship, resources, and industry expertise, particularly in the fields of autonomous systems and unmanned vehicle development. Boeing's commitment to supporting the next generation of engineers and innovators has played a critical role in the success of our club's initiatives and competitive entries.</h5>
						</div>
					</Grid>
					<Grid item xs={12} md={5} className="sponsor-image-container">
						<img src={Boeing} alt="Boeing" />
					</Grid>
				</Grid>
			</div>


			<div className="sponsor-Section roar-contact-container">
				<Grid container spacing={2} className="sponsor-section-container">
					<Grid item xs={12} md={5} className="sponsor-image-container">
						<img src={BPEQ} alt="Board of Professional Engineering Queensland" />
					</Grid>
					<Grid item xs={12} md={7} className="sponsor-text-container">
						<div>
							<h2><i>Board of Professional Engineering Queensland</i></h2>
							<h5>The Board of Professional Engineering Queensland (BPEQ) is a regulatory body that ensures engineers in Queensland maintain high standards of professional practice. Their sponsorship and support of the QUT Robotics Club ensure that our club members and executives are working under the highest ethical and professional standards. BPEQ has also supported our efforts to promote engineering as a career path for students through various outreach and networking initiatives.</h5>
						</div>
					</Grid>

				</Grid>
			</div>

			<div className="sponsor-Section roar-contact-container">
				<Grid container spacing={2} className="sponsor-section-container">
					<Grid item xs={12} md={8} className="sponsor-text-container">
						<div>
							<h2><i>Leaders in Digital Transformation</i></h2>
							<h5>Leap is a pioneering force in engineering and technology, empowering students and innovators to turn their ideas into reality. By providing access to cutting-edge resources, expert guidance, and advanced development tools, Leap has played a crucial role in enhancing our technical capabilities. Their support has enabled the QUT Robotics Club to push the boundaries of innovation, creating more sophisticated designs and solutions for our projects and competitions.</h5>
						</div>
					</Grid>
					<Grid item xs={12} md={4} className="sponsor-image-container leap-container">
						<img src={Leap} alt="Board of Professional Engineering Queensland" />
					</Grid>
				</Grid>
			</div>


		</div>
	);
}

export default Sponsors;