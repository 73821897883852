import React from 'react'

const Headshot = ({ exec }) => {
    return (
        <div className='headshot animate-on-scroll' id={exec.firestoreId}>
            <div className='headshot-image-border'>
            <img
                className="robots101-headshot-image"
                src={`/exec_photos/${exec.imagePath}`}
                alt={exec.name}
              />
            </div>

            <div className='name-badge'>
                <h2><b>{exec.name}</b></h2>
                <h3>{exec?.position}</h3>
            </div>
        </div>
    )
}

export default Headshot
