export default function getMenuItems() {
  return menuItems;
}


var menuItems = [
    {
      title: 'About',
      url: '/about',
    },
    {
      title: 'Team',
      url: '/team',
    },
    {
      title: 'Robots101',
      url: '/robots101',
    },
    {
      title: 'ROAR',
      url: '/arc',
    },
    {
    title: 'DRC',
    url: '/drc',
    },
    {
    title: 'Sponsors',
    url: '/sponsors',
    },
    {
    title: 'Contact Us',
    url: '/contact',
    },
  ];