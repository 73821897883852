// src/App.js
import React from 'react';
import ARCHP from "../images/elements/ARC-Backdrop-2.jpg";
import {Grid } from "@mui/material";
import {useEffect} from "react";


import Rover from "../images/new/roar/roar.png"
import Trophy from "../images/new/roar/trophy.png"

import Gallery from '../components/gallery.js';

import '../css/arc.css'


const ExecImage = ({ exec }) => {
  return (


        <div className='robots101-project-headshot roar-lead'>
          <div className="robots101-headshot-image-border">
            <img
                className="robots101-headshot-image"
                src={`/exec_photos/${exec.imagePath}`}
                alt={exec.name}
              />

          </div>
          <h2>{exec.name}</h2>
          <h5>{exec.position}</h5>  
        </div>

  );
}


const text_1 = "Our team started competing in the Australian Rover Challenge in 2023 with our first rover, Artemis. We poured countless hours into designing and building it, learning a lot along the way. In 2024, we returned with Perseus, a completely redesigned rover that built on everything we learned the previous year. It’s been an incredible journey of teamwork, problem-solving, and pushing the limits of what we can do in autonomous rover technology."


const Roar_Lead = {
    imagePath: 'aidan.jpg',
    position: 'Roar Technical Lead',
    name: "Aidan Lipinksi"
};

 

function ARC() {

    useEffect(() => {
            const handleScroll = () => {
            const elements = document.querySelectorAll(".animate-on-scroll");
            elements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < window.innerHeight - 200) {
                element.classList.add("visible");
                }
            });
            };
        
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, []);

  return (
    <div>
      <div class="roar_image-container">
          <img src={ARCHP} alt="Robotics" height="100%" width="100%" />
          <div class="roar_image-overlay"></div>
          <div class="roar_overlay-text">
            <h1>Remote Offworld Autonomous Rover</h1>
            <h3><b>R.O.A.R</b></h3>
          </div>
      </div>

      <div className="roar-intro-section">
        <div>
          <Grid container className="roar-grid">
            <Grid item xs={12} sm={12} md={7}>
              <div className='roar-contact-container'>
                <p>{text_1}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <ExecImage exec={Roar_Lead} />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="roar-achievments">
        <h1>Our Team's Achievments</h1>
        <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={5}>
            <div className='roar-achievment-container animate-on-scroll'>
              <div className='roar-achievment'>
                <h3>2023</h3>
                <h2>4th Place & Best New Team Award</h2>
                <h3>Rover: Artemis</h3>
              </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <img className='roar-achievment-trophy' src={Trophy} alt="Error Loading" />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <div className='roar-achievment-container animate-on-scroll'>
                <div className='roar-achievment'> 
                  <h3>2024</h3>
                  <h2>4th Place</h2>
                  <h3>Rover: Perseus</h3>
                </div>
              </div>
            </Grid>
          </Grid>
      </div>


      <div className='roar-gallery-container'>
        <h1>Our 2024 Trip Highlights</h1>
        <Gallery />
      </div>




      <div className="roar-container">
        <Grid container className="roar-grid">
          <Grid item xs={12} sm={12} md={6} className="roar-image-container animate-on-scroll">
            <div className="roar-image-wrapper">
              <h1 className="roar-title">Our Latest Rover: Perseus</h1>
              <img src={Rover} alt="Rover" className="roar-image" />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="roar-text-container animate-on-scroll">
          <div className="roar-contact-container">
              <h1>Are you Interested in our Rover team?</h1>
              <h3>
                Discover more about our ROAR program by visiting the official QUTRC ROAR website. You'll find detailed technical specifications, available team positions, the latest updates, news, and valuable sponsorship and partnership opportunities. Click below to explore further!
              </h3>
              <button className="roar-link" onClick={() => window.open("https://www.roar-qutrc.space/home", "_blank")}>QUTRC-ROAR</button>
            </div>
          </Grid>
        </Grid>
      </div>



    </div>
  );
}

export default ARC;
